import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import App from "./App";
import { API_get_currency_rate, API_login } from "./utils/http";
import { jwtDecode } from "jwt-decode";
import { useTasks, useTasksDispatch } from "./AppContext";
import {
  TOKEN_DOUBLE_RATE,
  TOKEN_NAME,
  TOKEN_RATE_AUD,
  TOKEN_RATE_USD,
  TOKEN_ROLE_APP,
  TOKEN_SINGLE_RATE,
  TOKEN_USERNAME_APP,
  dispatchTYPE,
} from "./utils/constDispatch";

function Login() {
  const [password, set_password] = useState("");
  const [username, set_username] = useState("");
  const [login_text, set_login_text] = useState("");
  const [login_status, set_login_status] = useState(false);

  const dispatch = useTasksDispatch();

  async function login() {
    API_login(username, password, (x) => {
      if (x.success) {
        sessionStorage.setItem(TOKEN_NAME, x.token);
        check_token(x.token);
      } else {
        set_login_text(x.error);
      }
    });
  }

  function check_token(token) {
    if (token != null) {
      let decode_token = jwtDecode(token);
      let current_date = new Date();

      if (decode_token.exp * 1000 < current_date.getTime()) {
        set_login_status(false);
      } else {
        sessionStorage.setItem(TOKEN_ROLE_APP, decode_token.role);
        // sessionStorage.setItem(TOKEN_ROLE_APP, 50);
        sessionStorage.setItem(TOKEN_USERNAME_APP, decode_token.username);

        set_login_status(true);
        dispatch({
          type: dispatchTYPE.login,
          user: {
            username: decode_token.username,
            role: decode_token.role,
          },
          login: { status: true },
        });
      }
    }
  }

  async function get_currency_rate() {
    API_get_currency_rate((x) => {
      if (x.success) {
        x = x.data;
        let currency_rate = x.currency_rate;
        let customer_rate = x.customer_rate;

        sessionStorage.setItem(TOKEN_RATE_AUD, currency_rate.AUD);
        sessionStorage.setItem(TOKEN_RATE_USD, currency_rate.USD);
        sessionStorage.setItem(TOKEN_SINGLE_RATE, customer_rate.single);
        sessionStorage.setItem(TOKEN_DOUBLE_RATE, customer_rate.double);
      } else {
      }
    });
  }

  useEffect(() => {
    let token = sessionStorage.getItem(TOKEN_NAME);
    check_token(token);
    get_currency_rate();
  }, []);

  if (login_status == false) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src="LOGO.jpg" height={300} style={{ marginTop: 30 }} />
        <TextField
          autoFocus
          style={{ margin: 10, width: 500 }}
          id="username"
          label="username"
          variant="outlined"
          value={username}
          onChange={(e) => {
            set_username(e.target.value);
          }}
        />
        <TextField
          style={{ margin: 30, width: 500 }}
          id="password"
          label="password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => {
            set_password(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              login();
            }
          }}
        />
        <Typography>{login_text}</Typography>
      </div>
    );
  } else {
    return <App />;
  }
}

export default Login;
