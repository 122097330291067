import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  API_clone_mess,
  API_delete_mess,
  API_edit_mess,
  API_get_activities,
  API_get_all_mess,
} from "./utils/http";

import { COLUMN_MESSTOOL } from "./utils/constDispatch";
import AppBarSearchBox from "./components/SearchBox";
import { DialogCus } from "./components/DialogCus";
import MessIter from "./components/messIter";

export default function MessTool() {
  const [data, setData] = useState(null);
  const [search_text, set_search_text] = useState("");
  const [data_filter, set_data_filter] = useState(null);
  const [row_click, set_row_click] = useState(null);
  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);
  const [new_code, set_new_code] = useState(null);
  const [activ, set_activ] = useState(null);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClickOpen1 = () => setOpen1(!open1);
  const handleClickOpen2 = () => setOpen2(!open2);

  async function get_data_messtool() {
    let data_result = [];

    await API_get_all_mess((data) => {
      if (data.success) {
        let data_1 = data.data;
        for (let index = 0; index < data_1.length; index++) {
          const element = data_1[index];

          try {
            element.link = element.attach_acti.length;
          } catch (error) {
            console.log(error);
            element.link = 0;
          }
          data_result.push(element);
        }
      }
    });

    setData(data_result);
    set_data_filter(data_result);

    set_selected_id(null);
    setRowSelection([]);
  }
  const handle_search = (search_text) => {
    console.log("search_text", search_text);

    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(data);
    } else {
      let data_tmp = data.filter((x) => {
        console.log("x", x);

        let str = x.mess_code.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  function confirmClone() {
    API_clone_mess(selected_id, new_code, (data) => {
      if (data.success) {
        get_data_messtool();
        handleClickOpen2();
        set_clone_ok(true);
      }
    });
  }

  async function get_data_activities() {
    await API_get_activities((data) => {
      if (data.success) {
        set_activ(data.data);
      }
    });
  }

  useEffect(() => {
    get_data_messtool();
    get_data_activities();
  }, []);

  if (!data) {
    return <LinearProgress />;
  }
  return (
    <Box style={{ flexDirection: "column", display: "flex" }}>
      <AppBarSearchBox
        title="Mess Tool"
        value={search_text}
        onChange={(e) => {
          console.log(e.target.value);

          if (e.target.value == "") {
            handle_search("");
            set_selected_id(null);
            set_row_click(null);
          }
          set_search_text(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handle_search(search_text);
            set_selected_id(null);
            set_row_click(null);
          }
        }}
      />

      <Dialog open={open1} onClose={handleClickOpen1}>
        <DialogTitle>Confirm deleting</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen1}>Close</Button>
          <Button
            onClick={() => {
              handleClickOpen1();
            }}
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>

      <DialogCus
        open={open2}
        title="Clone"
        handleClickOpen={handleClickOpen2}
        handleClickConfirm={confirmClone}
      >
        <DialogContent>
          <TextField
            placeholder="Enter new code"
            autoFocus
            value={new_code}
            onChange={(e) => set_new_code(e.target.value)}
            margin="dense"
            id="code"
            label="code"
            fullWidth
            variant="standard"
          />
        </DialogContent>
      </DialogCus>

      <Dialog open={open1} onClose={handleClickOpen1}>
        <DialogTitle>Confirm deleting</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen1}>Close</Button>
          <Button
            onClick={() => {
              handleClickOpen1();
              API_delete_mess(selected_id, (x) => {
                if (x.success) {
                  get_data_messtool();
                }
              });
            }}
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box style={{ display: "flex", padding: 20, justifyContent: "flex-end" }}>
        <Button
          style={{ marginLeft: 20 }}
          variant="outlined"
          endIcon={<CopyAllRoundedIcon />}
          onClick={() => {
            set_selected_id(null);
            set_row_click(null);
            setRowSelection([]);
          }}
        >
          Refesh
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="outlined"
          endIcon={<CopyAllRoundedIcon />}
          onClick={() => handleClickOpen2()}
          disabled={selected_id == null}
        >
          Clone
        </Button>
        <Button
          variant="outlined"
          disabled={selected_id == null}
          color="error"
          style={{ marginLeft: 20 }}
          endIcon={<DeleteRoundedIcon />}
          onClick={() => handleClickOpen1()}
        >
          Delete
        </Button>
      </Box>

      <Box
        sx={{
          height: 700,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box style={{ margin: 10 }}>
          <DataGrid
            density="compact"
            getRowId={(item) => item._id}
            rows={data_filter}
            columns={COLUMN_MESSTOOL}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            pageSizeOptions={[5, 10, 20]}
            rowSelectionModel={rowSelection}
            onRowSelectionModelChange={(e) => {
              setRowSelection(e);
              set_selected_id(e[0]);
              set_row_click(data.find((x) => x._id === e[0]));
            }}
            onCellDoubleClick={() => {}}
            disableMultipleRowSelection={true}
          />
        </Box>
      </Box>
      {row_click == null ? null : (
        <MessIter
          activ={activ}
          row_click={row_click}
          saveClick={(data) =>
            API_edit_mess(row_click._id, data, (data) => {
              if (data.success) get_data_messtool();
            })
          }
        />
      )}
    </Box>
  );
}
