import dayjs from "dayjs";
import {
  TOKEN_RATE_AUD,
  TOKEN_RATE_USD,
  TOKEN_ROLE_APP,
} from "./constDispatch";

const showRole = (role) => {
  if (role === 100) {
    return "ADMIN";
  } else if (role === 70) {
    return "OPERATOR";
  } else if (role === 50) {
    return "USER";
  }
};

const idToTimeDMMMYYYY = (id) => {
  let date_item = new Date(parseInt(id.substr(0, 8), 16) * 1000);
  date_item = dayjs(date_item).format("D MMM YYYY");

  return date_item;
};

const idToTimeYYYYMMD = (id) => {
  let date_item = new Date(parseInt(id.substr(0, 8), 16) * 1000);
  date_item = dayjs(date_item).format("YYYY/MM/DD");

  return date_item;
};

const DMY_to_YMD = (x) => {
  x = dayjs(x, "DD/MM/YYYY").format("YYYY/MM/DD");
  return x;
};

const checkAdminRole = () => {
  return sessionStorage.getItem(TOKEN_ROLE_APP) == 100;
};

const checkOperatorRole = () => {
  return sessionStorage.getItem(TOKEN_ROLE_APP) == 70;
};

const checkOper_or_admin = () => {
  let role = sessionStorage.getItem(TOKEN_ROLE_APP);

  return role == 100 || role == 70;
};

const makeid = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const render_number = (a) => {
  return Number(a)
    .toFixed(1)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(".0", "");
};

const render_vnd_to_other = (a, rate) => {
  a = a / rate;

  a = new Intl.NumberFormat("en-AU", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(a);

  return a;
};

const render_vnd_to_aud = (a) => {
  a = a * 1000;
  a = render_vnd_to_other(a, sessionStorage.getItem(TOKEN_RATE_AUD));
  return a;
};

const render_vnd_to_usd = (a) => {
  a = a * 1000;

  a = render_vnd_to_other(a, sessionStorage.getItem(TOKEN_RATE_USD));
  return a;
};

const render_number_onchange = (a) => {
  a = a.replaceAll(/,/g, "");
  a = Number(a)
    .toFixed(1)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(".0", "");

  return a;
};

const checkString = (value) => {
  if (typeof value === "string") {
    return value;
  } else {
    return "";
  }
};

const find_hotel_3_day = (booking_details, day_index) => {
  let hotel_this_day = "";
  try {
    hotel_this_day =
      checkString(booking_details.activities[day_index].accommondation.hotel) +
      " " +
      checkString(booking_details.activities[day_index].accommondation.address);
  } catch (error) {
    console.log("error", error);
  }

  let hotel_yesterday = "";
  if (day_index > 0) {
    try {
      hotel_yesterday =
        checkString(
          booking_details.activities[day_index - 1].accommondation.hotel
        ) +
        " " +
        checkString(
          booking_details.activities[day_index - 1].accommondation.address
        );
    } catch (error) {
      console.log("error", error);
    }
  }

  let hotel_tomorrow = "";
  if (day_index < booking_details.activities.length - 1) {
    try {
      hotel_tomorrow =
        checkString(
          booking_details.activities[day_index + 1].accommondation.hotel
        ) +
        " " +
        checkString(
          booking_details.activities[day_index + 1].accommondation.address
        );
    } catch (error) {
      console.log("error", error);
    }
  }

  return {
    hotel_this_day: hotel_this_day,
    hotel_yesterday: hotel_yesterday,
    hotel_tomorrow: hotel_tomorrow,
  };
};

const checkStringNullOrEmpty = (value) => {
  try {
    if (value == "" || value == null) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    return false;
  }
};

const convert_vietnamese = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};

const find_str_in_str = (str, search_text) => {
  str = convert_vietnamese(str);
  str = str.replace(/\W/g, "").toLocaleLowerCase();

  search_text = convert_vietnamese(search_text);
  search_text = search_text.replace(/\W/g, "").toLocaleLowerCase();

  return str.includes(search_text);
};

const createNumberTypeRoom = (info_room_type, acti_room_type) => {
  let info_room_type_x = info_room_type.split("+");

  let result_arr = [];

  info_room_type_x.forEach((info_room_type_item) => {
    let info_room_type_arr = info_room_type_item.split(" ");

    let number = "1";
    let double_or_single = "Double";

    for (let index = 0; index < info_room_type_arr.length; index++) {
      const element = info_room_type_arr[index];
      // find Number in string element
      let number_found = element.match(/\d+/g);
      if (number_found) {
        number = number_found[0];
      }
    }
    for (let index = 0; index < info_room_type_arr.length; index++) {
      const element = info_room_type_arr[index];
      // find string double or single in string element
      if (element.toLowerCase().includes("double")) {
        double_or_single = "Double";
      } else if (element.toLowerCase().includes("twin")) {
        double_or_single = "Twin";
      } else if (element.toLowerCase().includes("single")) {
        double_or_single = "Single";
      } else if (element.toLowerCase().includes("triple")) {
        double_or_single = "Triple";
      }
    }

    let result =
      number + " " + double_or_single + " " + acti_room_type + " Room";
    // console.log(result);
    result_arr.push(result);
  });

  let result_text = result_arr.join(", ");
  // console.log(result_text);

  return result_text;
};

// createNumberTypeRoom("11 Double/twin Room", "Deluxe");
// createNumberTypeRoom("1 Twin room + 1 Single room ", "Deluxe");

export {
  find_str_in_str,
  showRole,
  idToTimeDMMMYYYY,
  idToTimeYYYYMMD,
  DMY_to_YMD,
  checkAdminRole,
  makeid,
  checkOper_or_admin,
  checkOperatorRole,
  render_number,
  render_number_onchange,
  // render_vnd_to_other,
  render_vnd_to_aud,
  render_vnd_to_usd,
  find_hotel_3_day,
  checkStringNullOrEmpty,
  checkString,
  createNumberTypeRoom,
};
