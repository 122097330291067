import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Button, LinearProgress, Link } from "@mui/material";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SaveIcon from "@mui/icons-material/Save";
import ListActivities from "./components/ListActivities";
import {
  API_edit_booking,
  API_get_activities,
  API_get_bookings_id,
} from "./utils/http";
import { useTasks, useTasksDispatch } from "./AppContext";
import InputLeftInfo from "./components/InputLeftInfo";
import InputLeftPrice from "./components/InputLeftPrice";
import ListAccom from "./components/ListAccom";
import { dispatchTYPE } from "./utils/constDispatch";

export default function InputLeft({ booking_id, onExitClick }) {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();

  const [accommondation, set_accommondation] = useState(null);
  const [activities, set_activities] = useState(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const gen_accom_clean = (data_pdf_activities) => {
    let list_accom_clean = [];
    let list_accom = new Set();

    try {
      if (data_pdf_activities != null && accommondation != null) {
        data_pdf_activities.map((item) => {
          list_accom.add(item.accommondation.code);
        });

        for (const item_set of list_accom) {
          accommondation.map((item) => {
            if (
              item.code.toLowerCase().replace(/\W/g, "") ==
              item_set.toLowerCase().replace(/\W/g, "")
            ) {
              list_accom_clean.push(item);
            }
          });
        }
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
    return list_accom_clean;
  };

  const SaveChange = () => {
    let data_body = data_pdf;
    // let new_accom_gen = gen_accom_clean(data_pdf.activities);

    // dispatch({
    //   type: dispatchTYPE.accommondation_regenerate,
    //   value: new_accom_gen,
    // });

    // data_pdf.accommondation = new_accom_gen;

    for (var key in data_body) {
      if (key.startsWith("_")) {
        delete data_body[key];
      }
    }
    API_edit_booking(booking_id, data_body, (data) => {});
  };

  async function get_data_activities_accom() {
    API_get_activities((data) => {
      set_activities(data.data);
      set_accommondation(data.accom);
    });
  }

  async function get_data() {
    API_get_bookings_id(booking_id, (x) => {
      if (x.success) {
        let data = x.data;
        dispatch({
          type: dispatchTYPE.get_data_pdf,
          value: data,
        });
      }
    });
  }

  useEffect(() => {
    get_data_activities_accom();

    get_data();
  }, []);

  if (accommondation != null && data_pdf != null) {
    return (
      <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackRoundedIcon />}
            onClick={() => onExitClick()}
          >
            Exit
          </Button>

          <Button
            variant="outlined"
            endIcon={<SaveIcon />}
            onClick={() => SaveChange()}
          >
            Save
          </Button>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Infomation" />
          <Tab label="Activities" />
          <Tab label="Accommodation" />
          <Tab label="Price" />
        </Tabs>

        <Box style={{ height: "90%", overflow: "hidden", overflowY: "scroll" }}>
          {value == 0 ? <InputLeftInfo /> : null}
          {value == 1 ? (
            <ListActivities
              accommondation={accommondation}
              activities={activities}
            />
          ) : null}
          {value == 2 ? <ListAccom accommondation={accommondation} /> : null}
          {value == 3 ? <InputLeftPrice /> : null}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  }
}
