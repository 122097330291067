import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  DialogContent,
  TextField,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  API_clone_bookings,
  API_clone_bookings_sample,
  API_delete_bookings_sample,
  API_get_bookings_sample,
} from "./utils/http";

import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import {
  checkAdminRole,
  checkOper_or_admin,
  idToTimeYYYYMMD,
} from "./utils/tool";
import { COLUMN_BOOKING_SAMPLE, STATUS_CODE } from "./utils/constDispatch";
import { AlertCus } from "./components/AlertCus";
import AppBarSearchBox from "./components/SearchBox";
import { DialogCus } from "./components/DialogCus";
import InputLeft from "./InputLeft";
import OutputRight from "./components/pdf/OutputRight";

function AddBooking() {
  const [data, set_data] = useState(null);
  const [data_filter, set_data_filter] = useState(null);
  const [search_text, set_search_text] = useState("");
  const [open, setOpen] = useState(false);
  const [openSample, setopenSample] = useState(false);
  const [open_details, setOpen_details] = useState(false);

  const [new_bookings, setNewBookings] = useState("");
  const [new_bookings_sample, setNewBookingsSample] = useState("");

  const [clone_ok, set_clone_ok] = useState(null);
  const [api_status, set_api_status] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_status = Boolean(anchorEl);
  const [open1, setOpen1] = useState(false);

  const [filter_list, set_filter_list] = useState({
    proceeding: false,
    dealing: false,
    sample: true,
    done: false,
    date_from: null,
    date_to: null,
    all: false,
  });

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClickOpen = () => setOpen(!open);
  const handleClickOpenSample = () => {
    setopenSample(!openSample);
  };
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };

  const confirmDelete = () => {
    handleClickOpen1();
    API_delete_bookings_sample(selected_id, (x) => {
      // set_delete_ok(x.success);
      if (x.success) {
        get_data_booking();
      }
      // else {
      //   set_delete_status(x.message);
      // }
    });
  };

  const get_data_booking = async () => {
    await API_get_bookings_sample((x) => {
      if (x.success) {
        let data = x.data;

        let x_new = [];
        data.map((item) => {
          item.created_at = idToTimeYYYYMMD(item._id);
          x_new.push(item);
        });
        x_new = x_new.reverse();
        set_data(x_new);

        x_new = apply_filters(x_new, filter_list);
        set_data_filter(x_new);
      }
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(data);
    } else {
      let data_tmp = data.filter((x) => {
        let str =
          x.booking_number +
          x.customer_name +
          x.customer_email +
          x.customer_cell +
          x.package +
          x.day_start +
          x.created_by;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const apply_filters = (data, x_filter) => {
    if (x_filter.all) {
      return data;
    }

    let data_tmp = data.filter((x) => {
      if (x_filter.proceeding && x.status == STATUS_CODE.proceeding) {
        return true;
      } else if (x_filter.dealing && x.status == STATUS_CODE.dealing) {
        return true;
      } else if (x_filter.sample && x.status == STATUS_CODE.sample) {
        return true;
      } else if (x_filter.done && x.status == STATUS_CODE.done) {
        return true;
      } else if (
        !x_filter.proceeding &&
        !x_filter.dealing &&
        !x_filter.sample &&
        !x_filter.done &&
        x.status == null
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (x_filter.date_from != null) {
      data_tmp = data_tmp.filter((x) => {
        return x_filter.date_from < dayjs(x.day_start, "DD/MM/YYYY");
      });
    }

    if (x_filter.date_to != null) {
      data_tmp = data_tmp.filter((x) => {
        return dayjs(x.day_start, "DD/MM/YYYY") < x_filter.date_to;
      });
    }
    return data_tmp;
  };

  const onClickCloneSample = () => {
    handleClickOpenSample();
    API_clone_bookings_sample(selected_id, new_bookings_sample, (e) => {
      console.log(e);
      set_clone_ok(e.success);
      if (e.success) {
        get_data_booking();
      } else {
        set_api_status(e.message);
      }
    });
  };

  const onClickClone = () => {
    handleClickOpen();
    API_clone_bookings(selected_id, new_bookings, (e) => {
      console.log(e);
      set_clone_ok(e.success);
      if (e.success) {
        get_data_booking();
      } else {
        set_api_status(e.message);
      }
    });
  };

  if (open_details) {
    return (
      <Box>
        <Box style={{ display: "flex", flex: 1 }}>
          {/* <Box style={{ display: "flex", flex: 1 }}> */}
          <InputLeft
            booking_id={selected_id}
            onExitClick={() => {
              setOpen_details(false);
              get_data_booking();
            }}
          />
          {/* </Box> */}
          <Divider orientation="vertical" flexItem />
          {/* <Box style={{ display: "flex", flex: 1 }}> */}
          <OutputRight booking_id={selected_id} />
          {/* </Box> */}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <AlertCus
          condition={clone_ok}
          message="Clone"
          additon_mess={api_status}
        />

        <AppBarSearchBox
          title="Sample Booking"
          value={search_text}
          onChange={(e) => {
            if (e.target.value == "") {
              handle_search("");
              set_selected_id(null);
            }
            set_search_text(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handle_search(search_text);
              set_selected_id(null);
            }
          }}
        />

        {data_filter != null ? (
          <Box style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
                justifyContent: "flex-end",
              }}
            >
              {checkOper_or_admin() ? (
                <Button
                  style={{ marginLeft: 20 }}
                  disabled={selected_id == null}
                  variant="outlined"
                  endIcon={<CopyAllRoundedIcon />}
                  onClick={() => handleClickOpenSample()}
                >
                  Clone new sample
                </Button>
              ) : null}
              <Button
                style={{ marginLeft: 20 }}
                disabled={selected_id == null}
                variant="outlined"
                endIcon={<CopyAllRoundedIcon />}
                onClick={() => handleClickOpen()}
              >
                Clone new booking
              </Button>

              {checkAdminRole() ? (
                <Button
                  variant="outlined"
                  disabled={selected_id == null}
                  color="error"
                  style={{ marginLeft: 20 }}
                  onClick={() => handleClickOpen1()}
                >
                  Delete
                </Button>
              ) : null}
            </Box>

            <DialogCus
              open={openSample}
              title="Clone New Sample"
              handleClickOpen={handleClickOpenSample}
              handleClickConfirm={onClickCloneSample}
            >
              <DialogContent>
                <TextField
                  autoFocus
                  value={new_bookings_sample}
                  onChange={(e) => setNewBookingsSample(e.target.value)}
                  margin="dense"
                  id="name"
                  label="sample booking number"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
            </DialogCus>

            <DialogCus
              open={open1}
              title="Confirm delete"
              handleClickOpen={handleClickOpen1}
              handleClickConfirm={confirmDelete}
            ></DialogCus>

            <DialogCus
              open={open}
              title="Clone"
              handleClickOpen={handleClickOpen}
              handleClickConfirm={onClickClone}
            >
              <DialogContent>
                <TextField
                  autoFocus
                  value={new_bookings}
                  onChange={(e) => setNewBookings(e.target.value)}
                  margin="dense"
                  id="name"
                  label="booking number"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
            </DialogCus>
            <Box sx={{ height: "100%", width: "100%" }}>
              <DataGrid
                density="compact"
                getRowId={(item) => item._id}
                rows={data_filter}
                columns={COLUMN_BOOKING_SAMPLE}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[5, 10, 20]}
                rowSelectionModel={rowSelection}
                onRowSelectionModelChange={(e) => {
                  setRowSelection(e);
                  set_selected_id(e[0]);
                }}
                onCellDoubleClick={() => {
                  setOpen_details(true);
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "100%", marginTop: 10 }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    );
  }
}

export default AddBooking;
