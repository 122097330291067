import { TOKEN_NAME } from "./constDispatch";

// const APIBASE = "http://100.114.6.77:6002/api/";
// const APIBASE = "http://192.168.1.99:6002/api/";
// const APIBASE = "http://107.120.133.77:6002/api/";
const APIBASE = "https://admin.svietnamadventures.com/api/";
const GG_AUTH_LINK = APIBASE + "setting/auth";

const API_get_bookings_sample = async (callback) => {
  await fetch(APIBASE + "booking_sample", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_bookings_sample = async (
  from_id,
  to_booking_number,
  callback
) => {
  await fetch(APIBASE + "booking_sample/clone_sample", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_booking_number: to_booking_number.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_search_bookings = async (textSearch, callback) => {
  await fetch(APIBASE + "booking/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({ text: textSearch.toString() }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_edit_booking_sample = async (id, data, callback) => {
  await fetch(APIBASE + "booking_sample/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_bookings = async (callback) => {
  await fetch(APIBASE + "booking", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_edit_booking = async (id, data, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_bookings_id = async (id, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_delete_bookings_id = async (id, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_delete_bookings_sample = async (id, callback) => {
  await fetch(APIBASE + "booking_sample/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_bookings = async (from_id, to_booking_number, callback) => {
  await fetch(APIBASE + "booking/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_booking_number: to_booking_number.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_activities = async (callback) => {
  await fetch(APIBASE + "activities", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_activities = async (id, data, callback) => {
  await fetch(APIBASE + "activities/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_clone_activities = async (from_id, code, callback) => {
  await fetch(APIBASE + "activities/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      code: code,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};
const API_delete_activities = async (id, callback) => {
  await fetch(APIBASE + "activities/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_accommondation = async (callback) => {
  await fetch(APIBASE + "accommondation", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_edit_accommondation = async (id, data, callback) => {
  await fetch(APIBASE + "accommondation/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_accommondation = async (from_id, code, callback) => {
  await fetch(APIBASE + "accommondation/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      code: code,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((data) => {
      console.log(data);
      callback(data);
    });
};

const API_delete_accommondation = async (id, callback) => {
  await fetch(APIBASE + "accommondation/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((data) => {
      console.log(data);
      callback(data);
    });
};

const API_login = async (username, password, callback) => {
  await fetch(APIBASE + "user/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: username.toString(),
      password: password.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_changepassword = async (body, callback) => {
  await fetch(APIBASE + "user/", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_AddUser = async (body, callback) => {
  await fetch(APIBASE + "user/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_ResetPassword = async (username, callback) => {
  await fetch(APIBASE + "user/resetpass/" + username, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_GetUser = async (callback) => {
  await fetch(APIBASE + "user", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_DelUser = async (username, callback) => {
  await fetch(APIBASE + "user/" + username, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_EditUser = async (body, callback) => {
  await fetch(APIBASE + "user/edit", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_vendor = async (callback) => {
  await fetch(APIBASE + "vendor", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_vendor_att = async (id, callback) => {
  await fetch(APIBASE + "vendor/vendorgroup/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_vendor = async (id, data, callback) => {
  await fetch(APIBASE + "vendor/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_vendors_id = async (id, callback) => {
  await fetch(APIBASE + "vendor/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_delete_vendors_id = async (id, callback) => {
  await fetch(APIBASE + "vendor/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_vendorgroup = async (callback) => {
  await fetch(APIBASE + "vendorgroup", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_vendorgroup = async (id, data, callback) => {
  await fetch(APIBASE + "vendorgroup/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_delete_vendorgroup_id = async (id, callback) => {
  await fetch(APIBASE + "vendorgroup/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_vendorgroup = async (from_id, to_code, callback) => {
  await fetch(APIBASE + "vendorgroup/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_code: to_code.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_vendor = async (from_id, to_code, callback) => {
  await fetch(APIBASE + "vendor/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_code: to_code.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_create_vendor = async (data, callback) => {
  await fetch(APIBASE + "vendor", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_operating_summary = async (callback) => {
  await fetch(APIBASE + "operating/summary", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_operating_booking_id = async (id, callback) => {
  await fetch(APIBASE + "operating/booking_id/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_confirm_operating = async (data, callback) => {
  await fetch(APIBASE + "operating/confirm", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_delete_operating = async (id, callback) => {
  await fetch(APIBASE + "operating/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_operating = async (id, data, callback) => {
  await fetch(APIBASE + "operating/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_create_operation = async (booking_id, callback) => {
  await fetch(APIBASE + "operating/create", {
    method: "POST",
    body: JSON.stringify({
      booking_id: booking_id,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_regenerate_operation = async (booking_id, callback) => {
  await fetch(APIBASE + "operating/regenerate", {
    method: "POST",
    body: JSON.stringify({
      booking_id: booking_id,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_add_manually_operation = async (data, callback) => {
  await fetch(APIBASE + "operating/", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_currency_rate = async (callback) => {
  await fetch(APIBASE + "setting/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_currency_rate = async (id, data, callback) => {
  await fetch(APIBASE + "setting/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_gg_send_email = async (data, callback) => {
  await fetch(APIBASE + "setting/send-email", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_all_mess = async (callback) => {
  await fetch(APIBASE + "messtool/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_clone_mess = async (from_id, to_code, callback) => {
  await fetch(APIBASE + "messtool/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_code: to_code.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_delete_mess = async (id, callback) => {
  await fetch(APIBASE + "messtool/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_edit_mess = async (id, data, callback) => {
  await fetch(APIBASE + "messtool/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(TOKEN_NAME),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

export {
  //booking
  API_get_bookings,
  API_clone_bookings,
  API_get_bookings_id,
  API_edit_booking,
  API_delete_bookings_id,
  API_search_bookings,
  // activities
  API_get_activities,
  API_edit_activities,
  API_clone_activities,
  API_delete_activities,
  // accommondation
  API_get_accommondation,
  API_edit_accommondation,
  API_clone_accommondation,
  API_delete_accommondation,
  //users
  API_login,
  API_changepassword,
  API_DelUser,
  API_ResetPassword,
  API_GetUser,
  API_AddUser,
  API_EditUser,
  //vendor
  API_get_vendor,
  API_clone_vendor,
  API_get_vendors_id,
  API_edit_vendor,
  API_delete_vendors_id,
  API_create_vendor,
  API_get_vendor_att,
  // vendorgroup
  API_get_vendorgroup,
  API_edit_vendorgroup,
  API_clone_vendorgroup,
  API_delete_vendorgroup_id,
  // operating
  API_get_operating_summary,
  API_get_operating_booking_id,
  API_confirm_operating,
  API_delete_operating,
  API_create_operation,
  API_regenerate_operation,
  API_add_manually_operation,
  API_edit_operating,
  // sample bookings
  API_get_bookings_sample,
  API_clone_bookings_sample,
  API_edit_booking_sample,
  API_delete_bookings_sample,
  // setting
  API_get_currency_rate,
  API_edit_currency_rate,
  // google
  API_gg_send_email,
  GG_AUTH_LINK,
  // messtool
  API_get_all_mess,
  API_clone_mess,
  API_delete_mess,
  API_edit_mess,
};
