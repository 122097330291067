import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { COLUMN_ITINERRARY_MINI } from "../utils/constDispatch";

import _ from "lodash";

export default function MessIter(props) {
  let props_activ = props.activ;

  const [search_text, set_search_text] = useState("");
  const [rowSelection_not_contain, set_rowSelection_not_contain] = useState([]);
  const [rowSelection_contain, set_rowSelection_contain] = useState([]);

  const [data_filter, set_data_filter] = useState([]);
  const [list_att, set_list_att] = useState([]);

  const [message, set_message] = useState(props.row_click.message);

  const handle_search = (search_text) => {
    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(props_activ);
    } else {
      let data_tmp = props_activ.filter((x) => {
        let str = x.label.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  function AddServiceBtn(arr_add) {
    let x = list_att.concat(
      _.filter(props_activ, (v) => _.includes(arr_add, v._id))
    );

    let x_unique = _.uniqBy(x, function (e) {
      return e._id;
    });

    set_list_att(x_unique);
  }

  function RemoveService(id) {
    let x = [];
    list_att.forEach((e) => {
      if (e._id != id) {
        x.push(e);
      }
    });
    set_list_att(x);
  }

  useEffect(() => {
    let data_filter_new = props.activ.filter((activity) => {
      return !props.row_click.attach_acti.includes(activity._id);
    });

    set_data_filter(data_filter_new);

    let data_filter_new_1 = props.activ.filter((activity) => {
      return props.row_click.attach_acti.includes(activity._id);
    });

    set_list_att(data_filter_new_1);
  }, []);

  return (
    <Box style={{ flexDirection: "column", display: "flex", padding: 10 }}>
      <Box style={{ margin: 10, display: "flex", flexDirection: "column" }}>
        <TextField
          style={{ margin: 5 }}
          id="activities"
          variant="outlined"
          multiline
          minRows={5}
          value={message}
          onChange={(e) => {
            set_message(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          onClick={() => {
            props.saveClick({
              ...props.row_click,
              attach_acti: list_att.map((x) => x._id),
              message: message,
            });
          }}
        >
          Save
        </Button>
      </Box>
      <Box>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box style={{ flex: 1, margin: 5 }}>
            <Typography sx={{ flex: 1 }} variant="h5">
              List contained
            </Typography>
            <Box style={{ flexDirection: "row", display: "flex" }}>
              <Button
                style={{ margin: 10, flex: 1 }}
                variant="outlined"
                color="error"
                onClick={() => RemoveService(rowSelection_contain)}
              >
                Remove
              </Button>
            </Box>

            <DataGrid
              getRowId={(item) => item._id}
              rows={list_att}
              columns={COLUMN_ITINERRARY_MINI}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 20, 50]}
              rowSelectionModel={rowSelection_contain}
              onRowSelectionModelChange={(e) => set_rowSelection_contain(e)}
              keepNonExistentRowsSelected
            />
          </Box>

          <Box style={{ flex: 1, margin: 5 }}>
            <Typography sx={{ flex: 1 }} variant="h5">
              List not contain
            </Typography>
            <Box style={{ flexDirection: "row", display: "flex" }}>
              <Button
                style={{ margin: 10, flex: 1 }}
                variant="outlined"
                onClick={() => {
                  AddServiceBtn(rowSelection_not_contain);
                }}
              >
                Add
              </Button>
              <TextField
                style={{ margin: 10, flex: 5 }}
                id="search"
                label="search"
                variant="outlined"
                value={search_text}
                onChange={(e) => set_search_text(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handle_search(e.target.value);
                  }
                }}
              />
            </Box>

            <DataGrid
              getRowId={(item) => item._id}
              rows={data_filter}
              columns={COLUMN_ITINERRARY_MINI}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 20, 50]}
              rowSelectionModel={rowSelection_not_contain}
              onRowSelectionModelChange={(e) => {
                console.log(e);

                set_rowSelection_not_contain(e);
              }}
              keepNonExistentRowsSelected
              checkboxSelection
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
