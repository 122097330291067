import "./styles.css";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import { CreateContent } from "./CreateContent";
import { Button, Box } from "@mui/material";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        B
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        i
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active" : ""}
      >
        s
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        heading
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <img src="./icon/bullet-list.svg" width="20" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <img src="./icon/ordered-list.svg" width="20" />
      </button>
    </>
  );
};

const EditorCus = ({
  operation_details,
  booking_details,
  onSendEmailClick,
}) => {
  let content_init = CreateContent(operation_details, booking_details);

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      TextStyle.configure({ types: [ListItem.name] }),
    ],
    content: content_init,
  });

  return (
    <Box>
      <MenuBar editor={editor} />
      <Box
        style={{
          padding: 10,
          flex: 1,
          display: "flex",
          overflowY: "scroll",
        }}
      >
        <EditorContent editor={editor} />
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          let html_send = editor.getHTML();
          onSendEmailClick(html_send);
        }}
      >
        send email
      </Button>
    </Box>
  );
};

export default EditorCus;
