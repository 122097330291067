import React, { useState, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { API_get_operating_summary } from "./utils/http";

import { COLUMN_OPER } from "./utils/constDispatch";
import OperDetail from "./components/operating/OperDetail";
import AppBarSearchBox from "./components/SearchBox";

export default function Operating() {
  const [density, setDensity] = React.useState("compact");

  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);
  const [dataAPI, set_data] = useState(null);
  const [data_filter, set_data_filter] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(!open);

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(dataAPI);
    } else {
      let data_tmp = dataAPI.filter((x) => {
        let str =
          x.booking_ref + x.vendor_code + x.vendor_type + x.service_code;
        // +
        // x.day_index_detail;
        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  async function get_data() {
    await API_get_operating_summary((data) => {
      if (data.success) {
        set_data(data.data);
        set_data_filter(data.data);
      }
    });
  }

  useEffect(() => {
    get_data();
  }, []);

  if (open) {
    return (
      <OperDetail
        init_data={row_click}
        onGoBackClick={() => {
          handleClickOpen();
          get_data();
        }}
      />
    );
  } else {
    return (
      <Box style={{ flexDirection: "column", display: "flex" }}>
        <AppBarSearchBox
          title="Operation"
          value={search_text}
          onChange={(e) => {
            if (e.target.value == "") {
              handle_search("");
              set_selected_id(null);
            }
            set_search_text(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handle_search(search_text);
              set_selected_id(null);
            }
          }}
        />

        <Box style={{ paddingLeft: 20, paddingRight: 20 }}>
          {data_filter != null ? (
            <Box sx={{ height: "90%", width: "100%" }}>
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                getRowId={(item) => item._id}
                rows={data_filter}
                columns={COLUMN_OPER}
                initialState={{
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                density={density}
                onDensityChange={(newDensity) => {
                  setDensity(newDensity);
                }}
                pageSizeOptions={[5, 10, 20]}
                rowSelectionModel={rowSelection}
                onRowSelectionModelChange={(e) => {
                  setRowSelection(e);
                  set_selected_id(e[0]);
                  // set_row_click(dataAPI.find((x) => x.booking_id === e[0]));
                }}
                disableMultipleRowSelection={true}
                // onCellDoubleClick={() => handleClickOpen()}
              />
            </Box>
          ) : (
            <Box sx={{ width: "100%", marginTop: 10 }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}
