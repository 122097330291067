import React, { useState, useEffect } from "react";

import {
  Box,
  LinearProgress,
  Typography,
  Button,
  TextField,
  Dialog,
  Autocomplete,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  DialogTitle,
} from "@mui/material";

import { useTasks, useTasksDispatch } from "../AppContext";
import { dispatchTYPE } from "../utils/constDispatch";
import dayjs from "dayjs";

function ListActivities({ activities, accommondation }) {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();

  const [open, setOpen] = useState(false);

  const [value_adding, setValueAdding] = useState(null);
  const [index_adding, setIndexAdding] = useState(null);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  if (accommondation != null && activities != null && data_pdf != null) {
    return (
      <Box style={{}}>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClickOpen}
        >
          <DialogTitle>Add activities, finding activities by code</DialogTitle>
          <DialogContent>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disablePortal
              disableClearable
              id="combo-box-demo"
              options={activities}
              sx={{ width: 600, marginTop: 5 }}
              onChange={(e, value) => {
                setValueAdding(value);
              }}
              renderInput={(params) => <TextField {...params} label="CODE" />}
            />

            {value_adding == null ? (
              <div style={{ height: 600 }}></div>
            ) : (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <Typography variant="h5" color="text.secondary" gutterBottom>
                    CODE: {value_adding.label}
                  </Typography>
                  <Typography variant="body2">
                    location code: {value_adding.accomodation_code}
                  </Typography>
                  <Typography variant="body2">
                    Pickuptime: {value_adding.pickuptime}
                  </Typography>
                  <Typography variant="body2">
                    Transportation: {value_adding.transportation}
                  </Typography>
                  <Typography variant="body2">
                    Guide: {value_adding.guide}
                  </Typography>
                  <Typography variant="body2">
                    Activities: {value_adding.activities}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClickOpen();
                dispatch({
                  type: dispatchTYPE.activities_adding,
                  value: value_adding,
                  index_in_array: index_adding,
                  accom: accommondation,
                });
              }}
            >
              Confirm
            </Button>
            <Button onClick={handleClickOpen}>Close</Button>
          </DialogActions>
        </Dialog>

        {data_pdf.activities.map((item, index) => {
          return (
            <ItemActivitiy
              day_start={data_pdf.day_start}
              accommondation={accommondation}
              item={item}
              index={index}
              setIndexAdding={setIndexAdding}
              handleClickOpen={handleClickOpen}
            />
          );
        })}

        {data_pdf.activities.length === 0 ? (
          <Button
            variant="outlined"
            onClick={() => {
              handleClickOpen();
              setIndexAdding(0);
            }}
          >
            Add activities
          </Button>
        ) : null}
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  }
}

function ItemActivitiy({
  day_start,
  item,
  index,
  handleClickOpen,
  setIndexAdding,
  accommondation,
}) {
  // console.log("day_start", day_start == null);

  let day_index_dd_mm_yyyy = "";
  if (day_start != null) {
    day_index_dd_mm_yyyy = dayjs(day_start, "DD/MM/YYYY")
      .add(index, "day")
      .format("DD/MM/YYYY");
  }

  const dispatch = useTasksDispatch();

  const [show_details, set_show_details] = useState(false);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "#CFD8DC",
        padding: 1,
      }}
    >
      <Box
        style={{
          flexDirection: "row",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            borderStyle: "solid",
            borderWidth: "thin",
            borderColor: "#90A4AE",
            padding: 5,
            borderRadius: 5,
            backgroundColor: "#E1F5FE",
          }}
          onClick={() => set_show_details(!show_details)}
        >
          <Typography>
            Day {index + 1}: {item.label}
          </Typography>
          <Typography>{day_index_dd_mm_yyyy}</Typography>
          <Typography>Accom: {item.accomodation_code}</Typography>
        </Box>
        <Box>
          <Button
            style={{ margin: 6 }}
            variant="outlined"
            onClick={() => {
              handleClickOpen();
              setIndexAdding(index);
            }}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              dispatch({
                type: dispatchTYPE.activities_deleting,
                index_in_array: index,
              });
            }}
            variant="outlined"
            color="error"
          >
            Delete {index + 1}
          </Button>
        </Box>
      </Box>

      {show_details ? (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            disableClearable
            options={accommondation}
            getOptionLabel={(option) => option.code}
            sx={{ width: 400, margin: "5px" }}
            // defaultValue={{ code: item.accomodation_code }}
            onChange={(e, value) => {
              dispatch({
                type: dispatchTYPE.activities_accommondation_full,
                value: value,
                index_in_array: index,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Change accom code"
                value={params.code}
              />
            )}
          />
          <TextField
            style={{ margin: 5 }}
            id="activities"
            label="activities"
            variant="outlined"
            multiline
            minRows={2}
            defaultValue={"TBA"}
            value={item.activities}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.activities_activities,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <TextField
            style={{ margin: 5 }}
            id="accommodation"
            label="accommodation"
            multiline
            minRows={2}
            variant="outlined"
            defaultValue={"TBA"}
            value={item.accommondation.hotel}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.activities_accommondation,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <TextField
            style={{ margin: 5 }}
            id="accommodation address"
            label="accommodation address"
            multiline
            minRows={2}
            variant="outlined"
            defaultValue={"TBA"}
            value={item.accommondation.address}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.activities_accommondation_address,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <TextField
            style={{ margin: 5 }}
            id="accommodation room type"
            label="accommodation room type"
            multiline
            minRows={2}
            variant="outlined"
            defaultValue={"TBA"}
            value={item.accommondation.type}
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.activities_accommondation_type,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              style={{ margin: 5 }}
              id="pickuptime"
              label="pickuptime"
              fullWidth
              variant="outlined"
              multiline
              minRows={2}
              defaultValue={"TBA"}
              value={item.pickuptime}
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.activities_pickuptime,
                  value: e.target.value,
                  index_in_array: index,
                });
              }}
            />
            <TextField
              style={{ margin: 5 }}
              id="transportation"
              label="transportation"
              variant="outlined"
              defaultValue={"TBA"}
              value={item.transportation}
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.activities_transportation,
                  value: e.target.value,
                  index_in_array: index,
                });
              }}
            />
            <TextField
              style={{ margin: 5 }}
              id="guide"
              label="guide"
              variant="outlined"
              defaultValue={"TBA"}
              value={item.guide}
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.activities_guide,
                  value: e.target.value,
                  index_in_array: index,
                });
              }}
            />
          </Box>

          <TextField
            style={{ margin: 5 }}
            id="detail"
            label="detail"
            variant="outlined"
            defaultValue={"TBA"}
            value={item.detail}
            multiline
            onChange={(e) => {
              dispatch({
                type: dispatchTYPE.activities_details,
                value: e.target.value,
                index_in_array: index,
              });
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default ListActivities;
