import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import {
  API_get_activities,
  API_edit_activities,
  API_clone_activities,
  API_delete_activities,
} from "./utils/http";
import Addinginput from "./components/AddingInput";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { COLUMN_ITINERRARY } from "./utils/constDispatch";
import { AlertCus } from "./components/AlertCus";
import AppBarSearchBox from "./components/SearchBox";
import { DialogCus } from "./components/DialogCus";
import { checkOper_or_admin, idToTimeYYYYMMD } from "./utils/tool";

export default function Itinerary({}) {
  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);

  const [activities, set_activities] = useState(null);
  const [data_filter, set_data_filter] = useState(null);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [density, setDensity] = React.useState("compact");

  const [new_code, set_new_code] = useState("");
  const [edit_ok, set_edit_ok] = useState(null);
  const [clone_ok, set_clone_ok] = useState(null);
  const [accom, set_accom] = useState(null);

  async function get_data_activities() {
    API_get_activities((data) => {
      if (data.success) {
        let data_act = data.data;
        let x_new = [];

        data_act.map((item) => {
          item.created_at = idToTimeYYYYMMD(item._id);
          x_new.push(item);
        });

        set_activities(x_new);
        set_data_filter(x_new);
        set_accom(data.accom);
      }
    });
  }

  async function edit_data_activities(id, data) {
    API_edit_activities(id, data, (data) => {
      set_edit_ok(data.success);
      get_data_activities();
    });
  }

  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };
  const handleClickOpen2 = () => {
    setOpen2(!open2);
  };

  useEffect(() => {
    get_data_activities();
  }, []);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(activities);
    } else {
      let data_tmp = activities.filter((x) => {
        let str = x.label + x.accomodation_code + x.created_at;
        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const confirm_delete = () => {
    handleClickOpen1();
    API_delete_activities(selected_id, (x) => {
      if (x.success) {
        get_data_activities();
      }
    });
  };
  const confirmClone = () => {
    handleClickOpen2();
    API_clone_activities(selected_id, new_code, (e) => {
      set_clone_ok(e.success);
      if (e.success) {
        get_data_activities();
      }
    });
  };

  if (activities != null && data_filter != null) {
    return (
      <Box style={{ flexDirection: "column", display: "flex" }}>
        <AppBarSearchBox
          title="Itinerary"
          value={search_text}
          onChange={(e) => {
            if (e.target.value == "") {
              handle_search("");
              set_selected_id(null);
            }
            set_search_text(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handle_search(search_text);
              set_selected_id(null);
            }
          }}
        />

        <AlertCus condition={edit_ok} message="Edit" />
        <AlertCus condition={clone_ok} message="Clone" />

        {checkOper_or_admin() ? (
          <Box
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "flex-end",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Button
                disabled={selected_id == null}
                style={{ marginLeft: 20 }}
                variant="outlined"
                endIcon={<CopyAllRoundedIcon />}
                onClick={() => handleClickOpen2()}
              >
                Clone
              </Button>
              <Button
                disabled={selected_id == null}
                variant="outlined"
                color="error"
                style={{ marginLeft: 20 }}
                endIcon={<DeleteRoundedIcon />}
                onClick={() => handleClickOpen1()}
              >
                Delete
              </Button>
            </Box>
          </Box>
        ) : null}

        <DialogCus
          title="Edit Itinerary"
          handleClickOpen={handleClickOpen}
          open={open}
        >
          <Addinginput
            accom={accom}
            init_data={row_click}
            onSaveClick={(id, data) => {
              edit_data_activities(id, data);
              handleClickOpen();
            }}
          />
        </DialogCus>
        <DialogCus
          open={open1}
          title="Confirm delete"
          handleClickOpen={handleClickOpen1}
          handleClickConfirm={confirm_delete}
        ></DialogCus>

        <DialogCus
          open={open2}
          title="Clone"
          handleClickOpen={handleClickOpen2}
          handleClickConfirm={confirmClone}
        >
          <DialogContent>
            <TextField
              placeholder="Enter new code"
              autoFocus
              value={new_code}
              onChange={(e) => set_new_code(e.target.value)}
              margin="dense"
              id="code"
              label="code"
              fullWidth
              variant="standard"
            />
          </DialogContent>
        </DialogCus>

        <Box
          sx={{
            height: "100%",
            width: "90%",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            getRowId={(item) => item._id}
            rows={data_filter}
            columns={COLUMN_ITINERRARY}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            density={density}
            onDensityChange={(newDensity) => {
              setDensity(newDensity);
            }}
            pageSizeOptions={[5, 10, 20]}
            rowSelectionModel={rowSelection}
            onRowSelectionModelChange={(e) => {
              setRowSelection(e);
              set_selected_id(e[0]);
              set_row_click(activities.find((x) => x._id === e[0]));
            }}
            disableMultipleRowSelection={true}
            onCellDoubleClick={() => handleClickOpen()}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  }
}
